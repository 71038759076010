const stepId = {
  INFO_UMUM: "step-info-umum",
  BIAYA: "step-biaya",
  KATEGORI: "step-kategori",
  JADWAL_REGISTRASI: "step-jadwal-registrasi",
  PERATURAN: "step-rules",
  JADWAL_KUALIFIKASI: "step-jadwal-kualifikasi",
  SELESAI: "step-selesai",
};

export { stepId };
