const styles = {
  categoryBox: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#dedede",
    marginBottom: 10,
    padding: 10,
  },
};

export default styles;
