// referensi data API
const TEAM_CATEGORIES = {
  TEAM_INDIVIDUAL: "individu",
  TEAM_INDIVIDUAL_MALE: "individu male",
  TEAM_INDIVIDUAL_FEMALE: "individu female",
  TEAM_MALE: "male_team",
  TEAM_FEMALE: "female_team",
  TEAM_MIXED: "mix_team",
};

export default { TEAM_CATEGORIES };
