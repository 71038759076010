import * as React from "react";

export default function EventAdd() {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icons/plus-circle">
        <path
          className="svg-icon-path"
          id="Vector"
          d="M28 51.3333C40.8866 51.3333 51.3333 40.8866 51.3333 28C51.3333 15.1133 40.8866 4.66663 28 4.66663C15.1134 4.66663 4.66666 15.1133 4.66666 28C4.66666 40.8866 15.1134 51.3333 28 51.3333Z"
          stroke="#757575"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="svg-icon-path"
          id="Vector_2"
          d="M28 18.6666V37.3333"
          stroke="#757575"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="svg-icon-path"
          id="Vector_3"
          d="M18.6667 28H37.3333"
          stroke="#757575"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
