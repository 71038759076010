const PARTICIPANT = 1;
const WINNER_ELIMINATION = 2;
const ELIMINATION = 3;
const WINNER_QUALIFICATION = 4;
const WINNER_TEAM = 5;
const WINNER_TEAM_MIXED = 6;

export default {
  PARTICIPANT,
  WINNER_ELIMINATION,
  ELIMINATION,
  WINNER_QUALIFICATION,
  WINNER_TEAM,
  WINNER_TEAM_MIXED,
};
