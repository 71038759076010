import React from "react";

function IconX({ size = 24 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="80" height="80" transform="translate(0 0.5)" />
      <path
        d="M63.75 53H53.75C53.0596 53 52.5 53.5596 52.5 54.25V64.25C52.5 64.9404 53.0596 65.5 53.75 65.5H63.75C64.4404 65.5 65 64.9404 65 64.25V54.25C65 53.5596 64.4404 53 63.75 53Z"
        fill="currentColor"
      />
      <path
        d="M51.25 43H43.75C43.0596 43 42.5 43.5596 42.5 44.25V51.75C42.5 52.4404 43.0596 53 43.75 53H51.25C51.9404 53 52.5 52.4404 52.5 51.75V44.25C52.5 43.5596 51.9404 43 51.25 43Z"
        fill="currentColor"
      />
      <path
        d="M73.75 65.5H66.25C65.5596 65.5 65 66.0596 65 66.75V74.25C65 74.9404 65.5596 75.5 66.25 75.5H73.75C74.4404 75.5 75 74.9404 75 74.25V66.75C75 66.0596 74.4404 65.5 73.75 65.5Z"
        fill="currentColor"
      />
      <path
        d="M73.75 43H68.75C68.0596 43 67.5 43.5596 67.5 44.25V49.25C67.5 49.9404 68.0596 50.5 68.75 50.5H73.75C74.4404 50.5 75 49.9404 75 49.25V44.25C75 43.5596 74.4404 43 73.75 43Z"
        fill="currentColor"
      />
      <path
        d="M48.75 68H43.75C43.0596 68 42.5 68.5596 42.5 69.25V74.25C42.5 74.9404 43.0596 75.5 43.75 75.5H48.75C49.4404 75.5 50 74.9404 50 74.25V69.25C50 68.5596 49.4404 68 48.75 68Z"
        fill="currentColor"
      />
      <path
        d="M70 5.5H47.5C46.1739 5.5 44.9021 6.02678 43.9645 6.96447C43.0268 7.90215 42.5 9.17392 42.5 10.5V33C42.5 34.3261 43.0268 35.5979 43.9645 36.5355C44.9021 37.4732 46.1739 38 47.5 38H70C71.3261 38 72.5979 37.4732 73.5355 36.5355C74.4732 35.5979 75 34.3261 75 33V10.5C75 9.17392 74.4732 7.90215 73.5355 6.96447C72.5979 6.02678 71.3261 5.5 70 5.5ZM65 26.75C65 27.0815 64.8683 27.3995 64.6339 27.6339C64.3995 27.8683 64.0815 28 63.75 28H53.75C53.4185 28 53.1005 27.8683 52.8661 27.6339C52.6317 27.3995 52.5 27.0815 52.5 26.75V16.75C52.5 16.4185 52.6317 16.1005 52.8661 15.8661C53.1005 15.6317 53.4185 15.5 53.75 15.5H63.75C64.0815 15.5 64.3995 15.6317 64.6339 15.8661C64.8683 16.1005 65 16.4185 65 16.75V26.75Z"
        fill="currentColor"
      />
      <path
        d="M32.5 5.5H10C8.67392 5.5 7.40215 6.02678 6.46447 6.96447C5.52678 7.90215 5 9.17392 5 10.5V33C5 34.3261 5.52678 35.5979 6.46447 36.5355C7.40215 37.4732 8.67392 38 10 38H32.5C33.8261 38 35.0979 37.4732 36.0355 36.5355C36.9732 35.5979 37.5 34.3261 37.5 33V10.5C37.5 9.17392 36.9732 7.90215 36.0355 6.96447C35.0979 6.02678 33.8261 5.5 32.5 5.5ZM27.5 26.75C27.5 27.0815 27.3683 27.3995 27.1339 27.6339C26.8995 27.8683 26.5815 28 26.25 28H16.25C15.9185 28 15.6005 27.8683 15.3661 27.6339C15.1317 27.3995 15 27.0815 15 26.75V16.75C15 16.4185 15.1317 16.1005 15.3661 15.8661C15.6005 15.6317 15.9185 15.5 16.25 15.5H26.25C26.5815 15.5 26.8995 15.6317 27.1339 15.8661C27.3683 16.1005 27.5 16.4185 27.5 16.75V26.75Z"
        fill="currentColor"
      />
      <path
        d="M32.5 43H10C8.67392 43 7.40215 43.5268 6.46447 44.4645C5.52678 45.4021 5 46.6739 5 48V70.5C5 71.8261 5.52678 73.0979 6.46447 74.0355C7.40215 74.9732 8.67392 75.5 10 75.5H32.5C33.8261 75.5 35.0979 74.9732 36.0355 74.0355C36.9732 73.0979 37.5 71.8261 37.5 70.5V48C37.5 46.6739 36.9732 45.4021 36.0355 44.4645C35.0979 43.5268 33.8261 43 32.5 43ZM27.5 64.25C27.5 64.5815 27.3683 64.8995 27.1339 65.1339C26.8995 65.3683 26.5815 65.5 26.25 65.5H16.25C15.9185 65.5 15.6005 65.3683 15.3661 65.1339C15.1317 64.8995 15 64.5815 15 64.25V54.25C15 53.9185 15.1317 53.6005 15.3661 53.3661C15.6005 53.1317 15.9185 53 16.25 53H26.25C26.5815 53 26.8995 53.1317 27.1339 53.3661C27.3683 53.6005 27.5 53.9185 27.5 54.25V64.25Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default IconX;
